import React, { Component } from "react";
import "./App.css";

class Track extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidMount() {
    if (this.props.track !== null) {
      const child = this.props.track.attach();
      child.classList.add(`${!this.props.local ? `w-full` : `w-full`}`);
      this.ref.current.classList.add(this.props.track.kind);
      this.ref.current.appendChild(child);
    }
  }
  render() {
    return (
      <>
        {this.props.track.kind !== "video" && this.props.name ? (
          <div className="name font-bold">{this.props.name || ""} </div>
        ) : null}
        <div
          className={`track relative rounded-lg flex items-center content-center ${
            this.props.track.kind === "video"
              ? `h-full w-full ${!this.props.local ? `` : ``}`
              : ``
          }`}
          ref={this.ref}
        ></div>
      </>
    );
  }
}
export default Track;
