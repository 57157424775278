import axios from 'axios';

const JELOU_API_URL = 'https://api.jelou.ai/';
// import store from "./Store";
const JelouAPI = axios.create({
	baseURL: JELOU_API_URL,
	headers: {
		Authorization: 'Basic NzMyZDU4YWYtODM3Ni00MTcwLWE2YzItYWZiZmE4N2M4MzA5OjFkZGY5YzI0LWUzNDgtNDI5NC1hNTYyLThkN2Y5MWY3MzlhNw==',
		"Content-Type": "application/json",
	},
});

export default JelouAPI;
