import React from "react";
import Icon from "./Icon";

const RotateIcon = (props) => (
  <Icon
    viewBox="0 -25 512 512"
    className={props.className}
    width={props.width}
    height={props.height}
    strokeWidth={props.strokeWidth}
    fill={props.fill}
    stroke={props.stroke}
  >
    <path d="m472 282v-130c0-22.054688-17.945312-40-40-40h-30.359375c-25.695313 0-48.523437-16.34375-56.800781-40.667969l-6.050782-17.777343c-2.761718-8.105469-10.371093-13.554688-18.933593-13.554688h-127.773438c-8.398437 0-15.957031 5.308594-18.808593 13.207031l-6.925782 19.171875c-8.554687 23.699219-31.234375 39.621094-56.429687 39.621094h-29.917969c-22.054688 0-40 17.945312-40 40v230c0 22.054688 17.945312 40 40 40h352c22.054688 0 40-17.945312 40-40 0-11.046875 8.953125-20 20-20s20 8.953125 20 20c0 44.113281-35.886719 80-80 80h-352c-44.113281 0-80-35.886719-80-80v-230c0-44.113281 35.886719-80 80-80h29.917969c8.398437 0 15.957031-5.308594 18.808593-13.207031l6.925782-19.171875c8.554687-23.699219 31.234375-39.621094 56.429687-39.621094h127.773438c25.691406 0 48.519531 16.34375 56.796875 40.664062l6.050781 17.78125c2.761719 8.109376 10.371094 13.554688 18.9375 13.554688h30.359375c44.113281 0 80 35.886719 80 80v130c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20zm-242.816406 3c0-11.046875-8.953125-20-20-20h-51c-22.15625 0-40.183594 17.945312-40.183594 40v50c0 11.046875 8.953125 20 20 20s20-8.953125 20-20v-19.648438c23.265625 31.132813 60.328125 50.648438 101 50.648438 69.476562 0 126-56.523438 126-126s-56.523438-126-126-126c-44.839844 0-86.65625 24.136719-109.132812 62.984375-5.527344 9.5625-2.261719 21.796875 7.300781 27.328125 9.558593 5.53125 21.792969 2.261719 27.324219-7.296875 15.347656-26.535156 43.898437-43.015625 74.507812-43.015625 47.421875 0 86 38.578125 86 86s-38.578125 86-86 86c-30.476562 0-57.996094-16.035156-73.328125-41h23.511719c11.046875 0 20-8.953125 20-20zm0 0" />
  </Icon>
);

export default RotateIcon;
