import { Component } from "react";
//import * as Twilio from "twilio-client";
import JelouAPI from "./services/Http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneSlash,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import Lottie from "react-lottie";
import PaperPlaneGif from "./paperPlane.json";
import EndCallGif from "./endCall.json";
import Room from "./Room";
import RotateIcon from "./Icon/RotateIcon";
import Sound from "react-sound";
import "./App.css";
import ExpiredIcon from "./Icon/ExpiredIcon";
import { withTranslation } from "react-i18next";
const { connect } = require("twilio-video");

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: PaperPlaneGif,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: EndCallGif,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class App extends Component {
  constructor(props) {
    super(props);
    window.soundManager.setup({
      debugMode: false,
      ignoreMobileRestrictions: true,
    });
    this.state = {
      operatorName: "",
      clientToken: "",
      clientName: "",
      hasFinishedCall: false,
      device: {},
      loading: true,
      expired: false,
      room: null,
      identity: "",
      activeAudio: true,
      activeVideo: true,
      type: "",
      videoTrackFace: "user",
      clientAudio: true,
      onCall: true,
    };
    this.joinRoom = this.joinRoom.bind(this);
    this.returnToLobby = this.returnToLobby.bind(this);
    this.updateIdentity = this.updateIdentity.bind(this);
    this.removePlaceholderText = this.removePlaceholderText.bind(this);
    this.muteAudio = this.muteAudio.bind(this);
    this.stopVideo = this.stopVideo.bind(this);
    this.unmuteAudio = this.unmuteAudio.bind(this);
    this.startVideo = this.startVideo.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
    // this.closeWindow = this.closeWindow.bind(this);
  }
  async joinRoom() {
    const url = window.location.href.split("/");
    // headersData = new Headers();
    const shortId = url[url.length - 1];
    // headersData.append(
    //   "Authorization",
    //   `Basic NzMyZDU4YWYtODM3Ni00MTcwLWE2YzItYWZiZmE4N2M4MzA5OjFkZGY5YzI0LWUzNDgtNDI5NC1hNTYyLThkN2Y5MWY3MzlhNw==`
    // );
    // headersData.append("Content-Type", "application/json");

    // const requestOptions = {
    //   method: "GET",
    //   headers: headersData,
    //   redirect: "follow",
    // };
    try {
      const { data } = await JelouAPI.get(
        `v1/videocalls/public/token?videoCallId=${shortId}`
      );
      const roomType = data.data.type.toUpperCase();

      const room = await connect(data.data.token, {
        name: `room-operador-${data.data.room}`,
        audio: true,
        ...(roomType === "VIDEOCALL"
          ? { video: { facingMode: this.state.videoTrackFace } }
          : null),
      });

      room.on("trackDisabled", (track) => {
        if (track.kind === "audio") {
          this.setState({
            clientAudio: false,
          });
        }
      });
      room.on("trackEnabled", (track) => {
        if (track.kind === "audio") {
          this.setState({ onCall: false });
          this.setState({
            clientAudio: true,
          });
        }
      });
      const iteratorParticipant = Array.from(room.participants.values());
      iteratorParticipant.forEach((participant) => {
        participant.on("trackUnpublished", (track) => {
          console.log("track unpublished:", track);

          this.returnToLobby();
        });
      });

      this.setState({ room: room });
      this.setState({
        operatorName: data.data.operator,
        type: roomType,
        loading: false,
      });
    } catch (err) {
      console.log(err);
      setTimeout(() => {
        this.setState({
          expired: true,
        });
      }, 4000);
    }
  }

  switchCamera() {
    this.state.room.localParticipant.videoTracks.forEach(({ track }) => {
      track.restart({
        facingMode:
          this.state.videoTrackFace === "user" ? "environment" : "user",
      });
      this.setState({
        videoTrackFace:
          this.state.videoTrackFace === "user" ? "environment" : "user",
      });
    });
  }

  muteAudio() {
    this.state.room.localParticipant.audioTracks.forEach((publication) => {
      publication.track.disable();
      this.setState({
        activeAudio: false,
      });
    });
  }
  unmuteAudio() {
    this.state.room.localParticipant.audioTracks.forEach((publication) => {
      publication.track.enable();
      this.setState({
        activeAudio: true,
      });
    });
  }
  stopVideo() {
    this.state.room.localParticipant.videoTracks.forEach((publication) => {
      publication.track.disable();
      this.setState({
        activeVideo: false,
      });
    });
  }
  startVideo() {
    this.state.room.localParticipant.videoTracks.forEach((publication) => {
      publication.track.enable();
      this.setState({
        activeVideo: true,
      });
    });
  }
  removePlaceholderText() {
    this.inputRef.current.placeholder = "";
  }
  updateIdentity(event) {
    this.setState({
      identity: event.target.value,
    });
  }

  componentDidMount() {
    this.joinRoom();
  }

  // closeWindow = () => {
  //   const isiPad = navigator.userAgent.match(/iPad/i) != null;
  //   const isiPhone = navigator.userAgent.match(/iPhone/i) != null;

  //   if (isiPad || isiPhone) {
  //     this.setState({
  //       hasFinishedCall: true,
  //     });
  //   } else {
  //     window.close();
  //   }
  // };

  returnToLobby() {
    this.setState({
      hasFinishedCall: true,
    });

    this.setState({ room: null });
  }

  getCallerName = () => {
    let name = this.state.identity || "";
    name = name.match(/[A-Z][a-z]+/g) || [];
    name = name.join(" ");

    return name.length === 0 ? "Cliente" : name;
  };

  render() {
    const { t } = this.props;
    // const callerName = this.getCallerName();
    if (this.state.expired) {
      return (
        <section className="h-full w-full fixed inset-0 flex flex-col justify-center items-center">
          <ExpiredIcon width={300} height={300} className="mb-2" />
          <h1 className="text_primary text-2xl md:text-3xl mb-2">
            {t("Enlace caducado")}
          </h1>
          <h3 className="text-gray-400 text-center w-44">
            {t(
              "Lo sentimos, pero este enlace ha caducado y ya no está disponible."
            )}
          </h3>
        </section>
      );
    }

    if (this.state.hasFinishedCall) {
      return (
        <section className="h-full w-full fixed inset-0 flex flex-col justify-center items-center">
          <Lottie options={defaultOptions2} height={300} width={180} />
          <h1 className="text-gray-800 text-3xl mt-4">
            {t("Llamada finalizada")}
          </h1>
        </section>
      );
    }

    if (this.state.loading) {
      return (
        <section className="h-full w-full fixed inset-0 flex flex-col justify-center items-center">
          <Lottie options={defaultOptions} height={300} width={300} />
          <h1 className="text-gray-800 text-3xl">{t("Cargando...")}</h1>
        </section>
      );
    }

    return (
      <section
        className="w-screen overflow-auto max-w-screen min-h-screen inset-0 grid justify-center items-center"
        //style={{ height: "calc(100vh - 50px)" }}
      >
        {this.state.type === "CALL" ? (
          <div className="absolute z-10 inset-x-0 top-6 flex flex-row-reverse">
            <FontAwesomeIcon
              className={`buttons__ic text-white mr-6 ${
                this.state.clientAudio ? `hidden` : ``
              }`}
              icon={faMicrophoneSlash}
            />
          </div>
        ) : (
          <div className="absolute z-10 inset-x-0 inset-y-1/2 flex flex-row justify-center">
            <FontAwesomeIcon
              className={`buttons__ic text-white ${
                this.state.clientAudio ? `hidden` : ``
              }`}
              icon={faMicrophoneSlash}
            />
          </div>
        )}

        <article className="flex flex-col items-center">
          <Room
            returnToLobby={this.returnToLobby}
            room={this.state.room}
            operatorName={this.state.operatorName}
            type={this.state.type}
          />
          <Sound
            url="https://s3.us-west-2.amazonaws.com/cdn.devlabs.tech/outgoing_ringing.mp3"
            playStatus={
              this.state.onCall ? Sound.status.PLAYING : Sound.status.STOPPED
            }
            playFromPosition={0 /* in milliseconds */}
            playbackRate={1}
            loop={true}
          />
        </article>
        <nav className="absolute inset-x-0 bottom-10 w-full flex flex-row gap-4 justify-center">
          <button
            className={`buttons ${this.state.type === "CALL" ? `hidden` : ``} ${
              this.state.activeVideo ? `btn_active` : `btn_inactive`
            }`}
            onClick={() => {
              //this.disconnectTwilioVoice(this.clientToken);
              if (this.state.activeVideo) this.stopVideo();
              else this.startVideo();
            }}
          >
            <span>
              <FontAwesomeIcon
                className="buttons__ic text-white"
                icon={this.state.activeVideo ? faVideo : faVideoSlash}
              />
            </span>
          </button>
          <button
            className={`buttons ${this.state.type === "CALL" ? `hidden` : ``} ${
              this.state.activeVideo ? `btn_active` : `btn_inactive`
            }`}
            onClick={() => {
              this.switchCamera();
            }}
          >
            <span>
              <RotateIcon
                className="buttons__ic text-white fill-current"
                fillCurrent="text-white"
              />
            </span>
          </button>
          <button
            className={`buttons ${
              this.state.activeAudio ? `btn_active` : `btn_inactive`
            }`}
            onClick={() => {
              if (this.state.activeAudio) this.muteAudio();
              else this.unmuteAudio();
            }}
          >
            <span>
              <FontAwesomeIcon
                className="buttons__ic text-white"
                icon={this.state.activeAudio ? faMicrophone : faMicrophoneSlash}
              />
            </span>
          </button>
          <button
            className="buttons btn_end"
            onClick={() => {
              this.returnToLobby();
            }}
          >
            <span>
              <FontAwesomeIcon
                className="buttons__ic text-white"
                icon={faPhoneSlash}
              />
            </span>
          </button>
        </nav>
      </section>
    );
  }
}

export default withTranslation()(App);
