import React, { Component } from "react";
import Participant from "./Participant";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
// import Cronometer from "./Cronometer";

class Room extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remoteParticipants: Array.from(this.props.room.participants.values()),
    };

    this.leaveRoom = this.leaveRoom.bind(this);
  }
  componentDidMount() {
    // Add event listeners for future remote participants coming or going
    this.props.room.on("participantConnected", (participant) => {
      this.addParticipant(participant);
      console.log(participant);
    });
    this.props.room.on("participantDisconnected", (participant) => {
      this.removeParticipant(participant);
      console.log(participant);
    });

    window.addEventListener("beforeunload", this.leaveRoom);
  }

  componentWillUnmount() {
    this.leaveRoom();
  }
  addParticipant(participant) {
    console.log(`${participant.identity} has joined the room.`);

    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant],
    });
  }

  removeParticipant(participant) {
    console.log(`${participant.identity} has left the room`);

    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(
        (p) => p.identity !== participant.identity
      ),
    });
  }
  leaveRoom() {
    this.props.room.disconnect();
    this.props.returnToLobby();
  }
  render() {
    const { t } = this.props;
    return (
      <div className="room">
        <div className=" participants w-full h-full justify-center place-content-center">
          <div className="absolute inset-x-0 top-0 h-full w-full flex flex-1 flex-col justify-center bg-gray-800">
            {this.props.type === "CALL" && (
              <div className="flex flex-col items-center justify-self-center">
                <figure
                  className={`flex flex-col icAgentContainer items-center justify-self-center rounded-full p-8 mb-8`}
                >
                  <FontAwesomeIcon className="icAgent" icon={faUser} />
                </figure>

                <h4 className="text-center text-4xl mb-1 text-white">
                  {this.props.operatorName || t("Operador")}
                </h4>

                {/* <div className={`text-white text-xl mb-6 `}>
                  <Cronometer />
                </div> */}
              </div>
            )}
            <div
              className={`tracks-container overflow-hidden ${
                this.props.type === "VIDEOCALL" ? `h-full w-full` : ``
              } place-content-center`}
            >
              {this.state.remoteParticipants.map((participant) => (
                <Participant
                  key={participant.identity}
                  participant={participant}
                  name={
                    this.props.type === "VIDEOCALL"
                      ? this.props.operatorName
                      : ""
                  }
                />
              ))}

              {this.props.type === "VIDEOCALL" && (
                <div
                  className={`local-container absolute top-4 left-4 w-1/3 rounded-3xl bg-black flex flex-1 flex-col items-center justify-center content-center overflow-hidden`}
                >
                  <Participant
                    key={this.props.room.localParticipant.identity}
                    localParticipant="true"
                    participant={this.props.room.localParticipant}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Room);
